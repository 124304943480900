import React, { useState } from 'react';
import { Form, Input, Row, Col, Modal } from 'antd';
import Button from 'atoms/Button'
import velogic from '../assets/gcs-velogic.png'

// Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setUserRole, setUsernName, toggleProcessingModal, setRoleList } from 'redux/actions';

// Theme
import styled from "@emotion/styled";

// Common
import FONTS from 'common/Fonts';
import COLORS from 'common/Colors';
import { NON_EMPTY_REGEX, STATUS_CODE } from 'common/Constants';
import ResetPasswordModal from 'organisms/ResetPasswordModal';
import SwitchUserModal from 'organisms/SwitchUserModal';
import { CALL_API } from 'common/API';
import { DEVICE } from 'common/Device';

const DivContainer = styled.div`
  height: 100%;
  background: #d0d0d0;
  background: ${COLORS.PRIMARY};
  padding-top: 15%;
`

const StyledRightCol = styled(Col)`
  padding: 1rem 3rem;
  text-align: left;
  @media ${DEVICE.tablet}  {
    width: 100%;
  }
`

const StyledLeftCol = styled(Col)`
  display:flex;
  justify-content:right;
  align-items:center;
  border-right: solid 2px ${COLORS.WHITE};
  padding: 3rem;
  position: relative;
  strong{
    letter-spacing:1px;
    margin-top:20%;
    text-align:right;
    color: white;
  }
  @media ${DEVICE.tablet}  {
    display:flex;
    justify-content:center;
    border-right: none;
  }
`

const BrandLogoImage = styled.img`
  width: 50%;
  @media ${DEVICE.tablet}  {
    width: 80%;
    padding-bottom: 50px;
  }
`

const StyledCenterAlign = styled.h3`
  text-align: left;
  font-size: 1.2rem;
  padding-bottom: 10px;
  font-weight: ${FONTS.WEIGHT.HEAVY};
`;

const StyledForm = styled(Form)`
  .ant-form-item-label > label {
    color: ${COLORS.TEXT.WHITE};
  }
`

const StyleSubmitButton = styled(Button)`
  padding-top: 0px;
  padding-right: 0px;
  font-weight: bold;
`

const StyledErrorDiv = styled.div`
  color: #ffafaf;
  font-weight: bold;
  text-shadow: 0.5px 0.5px black;
  font-size: small;
  padding-top: 10px;
`

const ResetPasswordLink = styled.div`
  cursor: pointer;
`;

const Login = ({
  setLoggedIn,
  userRole,
  saveUsernName,
  updateProcessingModal,
  saveRoleList
}) => {
  const [userRoleModal, setUserRoleModal] = useState(false);
  const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false);
  // const [ selectedRole, setSelectedRole ] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  // const [ userRoleList, setUserRoleList ] = useState([]);

  const onFinish = async (values) => {
    setErrorMessage('');
    updateProcessingModal(true);
    const {
      code,
      first_name,
      last_name,
      role_id,
      token,
      roleList
    } = await CALL_API('authenticate-user', 'post', {
      username: values.username,
      password: values.password,
    });
    if (code === STATUS_CODE.AUTHENTICATION_FAILED) {
      setErrorMessage('Authentication failed. Please enter correct credentials.');
    } else if (code === STATUS_CODE.INVALID_PAYLOAD) {
      setErrorMessage('Something is wrong with data.');
    } else if (code === STATUS_CODE.AUTHENTICATION_SUCCESSFUL) {
      if (navigator.userAgent === 'application') {
        if (window.localStorage.getItem('username') !== values.username && window.localStorage.getItem('password') !== values.password) {
          const { confirm } = Modal;
          confirm({
            icon: '',
            closable: true,
            content: 'Do you want to save credentials?',
            async onOk() {
              window.localStorage.setItem('username', values.username);
              window.localStorage.setItem('password', values.password);
              setUserRoleModal(true);
            },
            onCancel() {
              setUserRoleModal(true);
            }
          });
        } else {
          if (role_id !== 4) setUserRoleModal(true);
          if (role_id !== 5) setUserRoleModal(true);
        }
      }
      window.localStorage.setItem('Tymeplusjwt', token);
      saveRoleList(roleList);
      userRole(role_id);
      saveUsernName(`${first_name} ${last_name}`);

      if (role_id === 4) {
        setLoggedIn();
      } else {
        if (navigator.userAgent !== 'application') setUserRoleModal(true);
      }
      if (role_id === 5) {
        setLoggedIn();
      } else {
        if (navigator.userAgent !== 'application') setUserRoleModal(true);
      }
    }
    updateProcessingModal(false);
  };

  const proceedWithLogin = () => {

  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <DivContainer>
      <Row>
        <StyledLeftCol md={12} sm={24}>
          <BrandLogoImage src={velogic} />
          <strong>1.1</strong>
        </StyledLeftCol>
        <StyledRightCol md={12} sm={24}>
          <Row>
            <Col md={18} sm={24} xs={24}>
              <StyledCenterAlign>Sign in</StyledCenterAlign>
              <StyledForm
                layout="vertical"
                name="basic"
                initialValues={{
                  username: navigator.userAgent === 'application' ? (window.localStorage.getItem('username') || '') : '',
                  password: navigator.userAgent === 'application' ? (window.localStorage.getItem('password') || '') : '',
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item
                  name="username"
                  rules={[
                    {
                      required: true,
                      pattern: NON_EMPTY_REGEX,
                      message: 'Please enter username!',
                    },
                  ]}
                >
                  <Input placeholder="User Name" />
                </Form.Item>

                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      pattern: NON_EMPTY_REGEX,
                      message: 'Please enter password!',
                    },
                  ]}
                >
                  <Input.Password placeholder="Password" />
                </Form.Item>

                <Row>
                  <Col span={12} className='font-semi-bold font-italic'>
                    <ResetPasswordLink onClick={() => setOpenResetPasswordModal(true)}>Forgot Password?</ResetPasswordLink>
                  </Col>
                  <Col span={12} className='align-right'>
                    {/* <Form.Item> */}
                    <StyleSubmitButton htmlType="submit" type="text">Go {'>'}</StyleSubmitButton>
                    {/* </Form.Item> */}
                  </Col>
                </Row>

                {
                  errorMessage && (
                    <StyledErrorDiv className='align-center'>
                      {errorMessage}
                    </StyledErrorDiv>
                  )
                }
              </StyledForm>
            </Col>
          </Row>
        </StyledRightCol>
      </Row>

      <SwitchUserModal
        onDashboardPg={false}
        userRoleModal={userRoleModal}
        closeModal={() => {
          setUserRoleModal(false);
          setLoggedIn(true);
        }}
      />

      <ResetPasswordModal
        openResetPasswordModal={openResetPasswordModal}
        toggleResetPasswordModal={() => setOpenResetPasswordModal(!openResetPasswordModal)}
      ></ResetPasswordModal>
    </DivContainer>
  );
}

const mapStateToProps = state => ({
  // userRoleId: getUserRoleId(state),
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    saveRoleList: setRoleList,
    userRole: setUserRole,
    saveUsernName: setUsernName,
    updateProcessingModal: toggleProcessingModal,
  },
  dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(Login);
