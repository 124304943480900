import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Table, Spin, Select, DatePicker, Space, Form, Tooltip, Modal, message, Menu, Dropdown, Input } from 'antd';
import Button from 'atoms/Button';
import styled from '@emotion/styled';
// Redux
import { connect } from 'react-redux';
import {
    getUserRoleId,
    getSelectedDepartmentId,
    getStatusListFromID,
    getAllDepartmentList,

} from 'redux/selectors';
import COLORS from 'common/Colors';
import { CALL_API } from 'common/API';
import { STATUS_CODE } from 'common/Constants';
import { DEVICE } from 'common/Device';
import { CSVLink } from "react-csv";
import AddRole from "organisms/AddRole";
import EditRole from 'organisms/EditRole';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { reduce, concat, indexOf, lowerCase, filter, uniqBy } from 'lodash';
import Confirm from '../../src/assets/Confirm.svg';

const StyledTable = styled(Table)`
  padding-top: 20px;
  table{
     table-layout: auto !important;
  }
  .ant-table-content {
    overflow-x: auto;
    max-height:500px;
  }
  .ant-table-cell {
    padding: 10px;
    height: 50px;
  }
  .ant-table-thead .ant-table-cell {
    font-weight: bold;
    color: ${COLORS.SECONDARY_BLACK}
  }
  .non-white {
    background: #f9f9f9;
  }
`;

const StyledButton = styled(Button)`
    width: 125px;
    color: white;
    background-color: #B7B7B7;
    border-radius: 5px; 
    margin-left: 5%;
`;

const StyledViewMenu = styled.div`
  width: 100px;
  cursor: pointer;
`;

const StyledModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
    padding-top: 20px;
    padding-bottom: 10px;
  }
  .ant-modal-title {
    text-align: center;
    font-size: 15px;
    padding-top: 3%;
  }
  .ant-modal-footer {
    display: none;
  }
`;

function Shift({
    userRoleId,
    selectedDepartmentId,
    allDepartmentList,
}) {
    const [addRole, toggleAddRole] = useState(false);
    const [editRole, toggleEditRole] = useState(false);
    const [RoleDetails, setRoleDetails] = useState({});
    const [shiftList, setShiftList] = useState([]);
    const [displayLocationLoader, setDisplayLocationLoader] = useState(false);
    const [shiftCSVlist, setShiftCSVlist] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState('');
    const [shiftFilterList, setShiftFilterList] = useState([]);
    const csvLinkEl = useRef();
    const [enableEdit, setEnableEdit] = useState(false);
    const [idSelected, setIdSelected] = useState(null);
    const [shiftStartTime, setShiftStartTime] = useState('');
    const [shiftEndTime, setShiftEndTime] = useState('');
    const [shiftErr, setShiftErr] = useState(false);

    // useEffect(() => {
    //    setSelectedDepartment(selectedDepartmentId);
    // }, [selectedDepartmentId]);

    useEffect(() => {
        var result
        if (selectedDepartment != '') {
            result = shiftList?.filter((data) => data.department_id === selectedDepartment);
        }
        else {
            result = shiftList;
        }
        setShiftFilterList(result);
    }, [selectedDepartment, shiftList])

    const handleShiftStartTime = (e) => {
        setShiftStartTime(e.target.value);
        // console.log("setShiftStartTime", e.target.value)
        // setShiftErr(false);
    }

    const handleShiftEndTime = (e) => {
        setShiftEndTime(e.target.value);
        // console.log("setShiftEndTime", e.target.value)
        // setShiftErr(false);
    }

    // const handleErrValidation = () => {

    //     return true;
    // }

    const CSVHeaders = [
        // { label: "Id", key: "user_shift_Id" },
        { label: "Employee Name", key: "name" },
        { label: "Department name", key: "department_name" },
        { label: "Shift start time", key: "shift_start_time" },
        { label: "Shift end time", key: "shift_end_time" },

    ];
    const columns = [
        // {
        //     title: 'Id',
        //     dataIndex: 'user_shift_Id',
        //     key: 'user_shift_Id',
        //     width: '10%',
        //     align: 'center',
        // },
        {
            title: 'Employee name',
            dataIndex: 'name',
            key: 'name',
            width: '10%',
            align: 'center'
        },
        {
            title: 'Department name',
            dataIndex: 'department_name',
            key: 'department_name',
            width: '10%',
            align: 'center'
        },
        {
            title: 'Shift start time',
            dataIndex: 'shift_start_time',
            key: 'shift_start_time',
            width: '10%',
            align: 'center',
            render: (_, render) => {
                if (enableEdit === true && render.user_shift_Id == idSelected) {
                    return (<div>
                        <Input type="time" name="shiftStartTime" value={shiftStartTime} onChange={handleShiftStartTime} />
                    </div>)
                } else {
                    return render.shift_start_time;
                }
            }
        },
        {
            title: 'Shift end time',
            dataIndex: 'shift_end_time',
            key: 'shift_end_time',
            width: '10%',
            align: 'center',
            render: (_, render) => {
                if (enableEdit === true && render.user_shift_Id == idSelected) {
                    return (<div>
                        <Input type="time" name="shiftEndTime" value={shiftEndTime} onChange={handleShiftEndTime} />
                    </div>)
                } else {
                    return render.shift_end_time;
                }
            }
        },
        {
            title: 'shift Type',
            dataIndex: 'shift_type',
            key: 'shift_type',
            width: '10%',
            align: 'center'
        },
        {
            title: 'Action',
            dataIndex: '',
            key: '',
            width: '10%',
            align: 'center',
            render: (_, record) => {
                if (enableEdit === true && record.user_shift_Id == idSelected) {
                    return (<>
                        <Button onClick={() => { setEnableEdit(false); setIdSelected(null) }} className="m-1">Cancel</Button>

                        <Button type="primary" onClick={() => onUpdate(record.user_shift_Id)} className="m-1">Update</Button>
                    </>
                    )
                } else {
                    return (<Dropdown overlay={<StyledViewMenu>
                        <Menu>
                            <Menu.Item onClick={() => { setEnableEdit(true); setIdSelected(record.user_shift_Id); console.log("record", record) }}>
                                Edit
                            </Menu.Item>
                        </Menu>
                    </StyledViewMenu>} trigger={['click']} placement="bottomRight">
                        <MoreVertIcon style={{ cursor: 'pointer' }} />
                    </Dropdown>)
                }
            }
        }
    ];

    const addLocation = (leaveObject, isViewOnly = false) => {
        toggleAddRole(true);
    }

    useEffect(() => {
        getRoleList();

    }, [setShiftList]);

    const getRoleList = async () => {
        setDisplayLocationLoader(true);
        await CALL_API('user-shift', 'get', {
        }).then(function (response) {
            console.log("response", response)
            setShiftList(response);
            setDisplayLocationLoader(false);
        }).catch(function (error) {
            console.log("[getRoleList], Error-->", error);
        })
    }

    const shiftTimingerror = () => {
        Modal.info({
            title: 'Shift working timings should be minimum 1 hour',
        });
    };

    const onUpdate = async (Id) => {
        console.log("PATCH 1", Id);
        console.log("shiftEndTime", shiftEndTime)
        console.log("shiftStartTime", shiftStartTime)

        const startHour = parseInt(shiftStartTime.slice(0, 2));
        const startMinute = parseInt(shiftStartTime.slice(3));
        const endHour = parseInt(shiftEndTime.slice(0, 2));
        const endMinute = parseInt(shiftEndTime.slice(3));
        const startMinutes = startHour * 60 + startMinute;
        const endMinutes = endHour * 60 + endMinute;
        console.log("endMinutes", endMinutes);
        console.log("startMinutes", startMinutes);
        console.log("condition", endMinutes >= startMinutes + 60)

        if (endMinutes >= startMinutes + 60) {
            await CALL_API('user-shift', 'patch', {
                "user_shift_Id": Id,
                "shift_start_time": shiftStartTime,
                "shift_end_time": shiftEndTime,
                "shift_type": "not general shift"
            });
            setEnableEdit(false);
            setIdSelected(null);
            setShiftStartTime("");
            setShiftEndTime("");
            getRoleList();
        } else {
            // setShiftErr(true);
            shiftTimingerror();
        }
    }

    const prepareCSV = async () => {
        if (shiftFilterList.length > 0) {
            setShiftCSVlist(
                reduce(shiftFilterList, function (result, list) {
                    result.push({
                        // "Id": list.user_shift_Id,
                        "Employee name": list.name,
                        "department name": list.department_name,
                        "Shift start time": list.shift_start_time,
                        "Shift end time": list.shift_end_time,
                    });
                    return result;
                }, [])
            );
            setTimeout(() => {
                csvLinkEl.current.link.click();
            });
        } else {
            message.error(`No data available to download.`);
        }
    }


    return (
        <>
           
                <Row className='page-title'>
                    <Col span={12} style={{ fontSize: "20px", fontWeight: "bold" }}></Col>
                    <Col span={12} className="align-right">
                        <Button type="link" style={{ border: "1px solid", marginBottom: "2%" }} onClick={prepareCSV}>
                            Export CSV file
                        </Button>
                        <CSVLink
                            header={CSVHeaders}
                            data={shiftCSVlist}
                            filename={"shift-management.csv"}
                            asyncOnClick={true}
                            ref={csvLinkEl}
                        />
                    </Col>
                </Row>

            <Select
                className='selectbox'
                placeholder="Department"
                defaultValue={''}
                options={concat({
                    department_id: '',
                    department_name: 'All Departments'
                }, allDepartmentList)}
                fieldNames={{
                    label: 'department_name',
                    value: 'department_id'
                }}
                onChange={(id) => { setSelectedDepartment(id); console.log("id", id) }}
            />

          
                <Spin spinning={displayLocationLoader}>
                    <StyledTable
                        dataSource={shiftFilterList}
                        columns={columns}
                        rowClassName={(record, index) => index % 2 === 0 ? '' : 'non-white'}
                        rowKey='role_id'
                        pagination={{
                            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                            pageSize: 20,
                            showSizeChanger: false
                        }}
                    />
                </Spin>
            {/* 
            {userRoleId == 5 && (
                <AddRole
                    isModalVisible={addRole}
                    closeModal={() => toggleAddRole(false)}
                    getRoleList={getRoleList}
                    RoleDetails={RoleDetails}
                />)}

            {userRoleId == 5 && (
                <EditRole
                    isModalVisible={editRole}
                    closeModal={() => toggleEditRole(false)}
                    getRoleList={getRoleList}
                    RoleDetails={RoleDetails}
                />)} */}
        </>
    );
}

const mapStateToProps = state => ({
    userRoleId: getUserRoleId(state),
    allDepartmentList: getAllDepartmentList(state),
    statusListFromID: getStatusListFromID(state, [4, 5, 6]),
    selectedDepartmentId: getSelectedDepartmentId(state),
});

export default connect(mapStateToProps)(Shift);