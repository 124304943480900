import React,{ useState } from 'react'
import styled from '@emotion/styled';

const StyledViewmore = styled.div`
  a{
    color: #5B86E5 !important;
    font-size:12px;
    font-weight:bold;
   
  }
`;

function ViewMore ({value}) {
  const [isExpanded, setIsExpanded] = useState(false);

  return(
    <>
        {value == null || value == "" ? "-" : (<>
            {value.length  > 50 ? (
                    <>
                    {isExpanded ? (
                        <StyledViewmore>{value} <a onClick={() => setIsExpanded(false)} >View less</a></StyledViewmore>
                    ) : (
                        <StyledViewmore>
                        {value.substring(0, 50)}... <a onClick={() => setIsExpanded(true)} >View more</a>
                        </StyledViewmore>
                    )}
                    </>
                ):<>{value}</>}
        </>)}        
    </>
  )
}


export default ViewMore