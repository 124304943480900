import React, { useEffect, useState } from 'react';
import { Bar } from '@ant-design/plots';
import styled from '@emotion/styled';
import COLORS from 'common/Colors';
import { Spin } from 'antd';
import { concat, includes, isNaN, map, max, size } from 'lodash';

const StyledTitle = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  color: #141414;
  margin-bottom: 10px;
`;

const StyledChartContainer = styled.div`
  border: solid 1px #9B9B9B;
  border-radius: 3px;
  padding: 20px;
  margin-bottom: 25px;
  .name-title {
    padding: 3px 10px;
    font-size: 1rem;
    padding-bottom: 15px;
  }
`;

function DemographicsAgeDistribution({ report }) {
 
  const config = {
    data: report,
    height:300,
    isStack: true,
    xField: 'count',
    yField: 'age_group',
    seriesField: 'sex',
    label: {
      position: 'middle',
      content:''
    },
    columnStyle: {
      cursor: 'pointer'
    },
    legend: {
      layout: 'horizontal',
      position: 'top-right'
  },
  xAxis: {
    // max: isNaN(max(map(report, 'count'))) ? 100 : max(map(report, 'count')) + 25,
    grid: {
      line: {
        style: {
          opacity: 0
        }
      }
    }
  },
    // theme: {
    //   colors10: [
    //     // '#0F1C73',
    //     // '#1A449F',
    //     '#2B67BA',
    //     '#3C88C0',
    //     '#4BA7B3',
    //     '#5AC39B',
    //     '#6ADD7F',
    //     '#A8DD6A',
    //     '#DDDD6A',
    //   ],
    // },
  };
  return (
    <>      
      <StyledTitle>
         Age Distribution (MRU population only)
      </StyledTitle>
      <StyledChartContainer>
         <Bar
            {...config}
          />
      </StyledChartContainer>
    </>
  );
}


export default DemographicsAgeDistribution