import React, { useEffect, useState } from 'react';
import { Line  } from '@ant-design/plots';
import styled from '@emotion/styled';
import COLORS from 'common/Colors';
import { Spin } from 'antd';
import { concat, includes, isNaN, map, max, size } from 'lodash';
import { DatePicker, Space } from 'antd';
import { connect } from 'react-redux';
import {
  getAllDepartmentList,
} from 'redux/selectors';

const StyledTitle = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  color: #141414;
  margin-bottom: 10px;
`;

const StyledChartContainer = styled.div`
  border: solid 1px #9B9B9B;
  border-radius: 3px;
  padding: 20px;
  margin-bottom: 25px;
  .name-title {
    display:flex;
    justify-content:space-between;
    padding: 3px 10px;
    font-size: 1rem;
    padding-bottom: 15px;
  }
`;

function ProductivityTopAreasAttrition({
  allDepartmentList
}) {
  const [ displayReportLoader, toggleReportLoader ] = useState(false);
  const [ reportData, setReportData ] = useState([
    {
        type: 'Waiter',
        ratio: 300
    },
    {
        type: 'Trainee',
        ratio: 258
    },
    {
        type: 'Steward',
        ratio: 240
    },
    {
        type: 'Assistant Cook',
        ratio: 200
    },
    {
        type: 'Cook',
        ratio: 170
    },
    {
        type: 'Staff',
        ratio: 100
    },
  ]);

  const mappedCollections = allDepartmentList.map(val => ({
    type: val.short_department_name,
    ratio: 45.5,
  }));
  const config = {
    data: mappedCollections,
    xField: 'type',
    yField: 'ratio',
    height: 300,
    label:false,
    point: {
      size: 5,
      // shape: 'diamond'
    },
    tooltip: {
      showMarkers: false,
    },
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: '#000',
          fill: 'red',
        },
      },
    },
    yAxis: {
        max: isNaN(max(map(reportData, 'ratio'))) ? 100 : max(map(reportData, 'ratio')) + 15,

    },
    xAxis: {
      label: {
        formatter: (value) => value.slice(0, 3),
        offset: 10,
        autoHide: false,
        autoRotate: true,
      },
    },
    interactions: [
      {
        type: 'marker-active',
      },
    ],
    }
  return (
    <>      
      <StyledTitle>
         <div style={{display:'flex',justifyContent:'space-between'}}>
                 Top Areas of Attrition
          </div>    
      </StyledTitle>
      <StyledChartContainer>
        <Spin spinning={displayReportLoader}>
          <Line 
            {...config}
             />
        </Spin>
      </StyledChartContainer>
    </>
  );
}
const mapStateToProps = state => ({
  allDepartmentList: getAllDepartmentList(state),
});


export default connect(mapStateToProps) (ProductivityTopAreasAttrition)