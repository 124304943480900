import React, { useEffect, useState, useRef } from 'react';
import styled from '@emotion/styled';
import COLORS from 'common/Colors';
import { Spin } from 'antd';
import { concat, includes, isNaN, map, max, size } from 'lodash';
import { Row, Col, Button } from 'antd';
import { DEVICE } from 'common/Device';
import { DatePicker, Space } from 'antd';
import ProductivityManagerToStaffRatio from '../molecules/ProductivityManagerToStaffRatio'
import ProductivityTopAreasAttrition from '../molecules/ProductivityTopAreasAttrition';
import ProductivityBasicSalaryInfo from '../molecules/ProductivityBasicSalaryInfo';
import ProductivityOvertime  from '../molecules/ProductivityOvertime';
import JsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const StyledTitle = styled.div`
  padding-left: 10px;
  padding-bottom: 10px;
`;

const StyledFilterRow = styled(Row)`
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  .top_row{
    width:100%;
    display:flex;
    justify-content:space-between;
  }
  .select_field{
    display: flex;
    gap:10px;
  }
  .selectbox{
    width:200px;
    // width:100%;
  }
  @media ${DEVICE.tablet}  {
    .select_field{
      width:100%;
      display: flex;
      flex-direction:column;
    }
    .selectbox{
      width:100%;
    }
    .align-right{
      width:100%;
      margin-top:10px;
      float:right;
    }
  }

`;

function Productivity({}) {
  const containerRef = useRef(null);
  const generatePDF = () => {
    const reportElement = containerRef.current;
    const reportHeight = reportElement.clientHeight;
    html2canvas(reportElement).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new JsPDF('p', 'pt', [reportHeight, reportHeight]);
      pdf.addImage(imgData, 'PNG', 0, 0, reportHeight, reportHeight);
      pdf.save('Productivity.pdf');
    });
  }
  return (
    <>      
      <StyledTitle  className='page-title-head'>
          Productivity
      </StyledTitle>
      <StyledFilterRow>
        <Row className='top_row'>
          <div className='select_field'>
              <DatePicker  className='selectbox' picker="year"  />
          </div>
          {/* <Col className='align-right'>
              <Button type="primary" onClick={generatePDF}><span className='add-title'>Download as PDF</span></Button>
          </Col> */}
        </Row>
      </StyledFilterRow>
      <StyledFilterRow gutter={16}  ref={containerRef}>
         <Col md={24} sm={24} xs={24} xl={12}>
            <ProductivityManagerToStaffRatio />
         </Col>
         <Col md={24} sm={24} xs={24} xl={12}>
             <ProductivityBasicSalaryInfo />
         </Col>
         <Col md={24} sm={24} xs={24} xl={12}>
             <ProductivityOvertime />
         </Col>
         <Col md={24} sm={24} xs={24} xl={12}>
            <ProductivityTopAreasAttrition />
         </Col>
      </StyledFilterRow>
    </>
  );
}


export default Productivity