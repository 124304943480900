import { connect } from 'react-redux';
import { bindActionCreators  } from 'redux';
import { getUserRoleId, getSelectedDepartmentId, getStatusListFromID } from 'redux/selectors';
import { PlusOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import AddDepartment from 'organisms/AddDepartment';
import EditDepartment from 'organisms/EditDepartment';
import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Table, Spin, Modal, message } from 'antd';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import Button from 'atoms/Button';
import { MoreOutlined } from '@ant-design/icons';
import { CSVLink } from "react-csv";
import { toggleProcessingModal } from 'redux/actions';
import { CALL_API } from 'common/API';
import COLORS from 'common/Colors';
import { reduce } from 'lodash';
import Confirm from '../../src/assets/Confirm.svg';

const StyledTitle = styled.div`
  .page-title-head{ 
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
  }
`;

const Styleddot = styled.div`
.btn:focus{
  box-shadow: unset;
}
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: unset;
}
.dropdown-item:focus, .dropdown-item:hover{
  background-color: unset;
}
.dropdown-menu {
  min-width: 10rem;
  box-shadow: 0px 3px 6px #00000029;
  border: unset;
  border-radius: 5px;
}
`;

const StyledRoleCol = styled(Col)`
  .add-button {
    background: #5B86E5;
    font-size: 16px;
    font-weight: 600;
    color: #FFFFFF;
    box-shadow: none;
    padding: 0px 10px 0px 10px;
    cursor: pointer;
  }
  .add-title{
    margin-top: 1px;
    vertical-align: text-bottom;
  }
  .ant-btn .anticon.anticon-plus > svg {
    margin-bottom: 10px;
  }
`;

const StyledTable = styled(Table)`
  padding-top: 20px;
  .ant-table-cell {
    padding: 10px;
  }
  .ant-table-content {
    overflow-x: auto;
    max-height:500px;
  }
  .ant-table-thead .ant-table-cell {
    font-weight: bold;
    color: ${COLORS.SECONDARY_BLACK}
  }
  .non-white {
    background: #f9f9f9;
  }
`;

const Styledexportbutton = styled.div`
  .exportbtn{
    border: 1px solid;
  }
`;

const StyledButton = styled(Button)`
    width: 150px;
    color: white;
    background-color: #B7B7B7;
    border-radius: 5px; 
    margin-left: 5%;
`;

const StyleddeactivateModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
    padding: 35px 0px 20px 0px;
  }
  .ant-modal-content{
    box-shadow: 0px 0px 8px #00000029;
    border-radius: 5px;
  }
  .ant-modal-title {
    text-align: center;
    font-size: 16px;
  }
  .ant-modal-body{
    padding: 0px 0px 35px 0px;
  }
  .ant-modal-close-x{
    display: none;
  }
  .page-title-head{
    justify-content: center;
  }
  .ant-modal-footer {
    display: none;
  }
`;

const StyledModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
  }
  .ant-modal-content{
    box-shadow: 0px 0px 8px #00000029;
    border-radius: 5px;
  }
  .ant-modal-body{
    padding: 24px 0px 30px 0px;
  }
  .modal-body{
    justify-content: center;
    margin-top: 10px;
  }
  .ant-modal-close-x{
    color: #151515;
  }
  .modal-body-content{
    justify-content: center;
    font-size: 16px;
    color: #151515;
  }
  .ant-modal-footer {
    display: none;
  }
`;

function Department(){
  
  const [ displayAddDepartmentModal, toggleAddDepartmentModal ] = useState(false);
  const [ displayEditDepartmentModal, toggleEditDepartmentModal ] = useState(false);
  const [ DepartmentDetails, setDepartmentDetails ] = useState({});
  const [ CSVDepartmentList, setCSVDepartmentList ] = useState([]);
  const [ displayDepartmentLoader, setDisplayDepartmentLoader ] = useState(false);
  const [ deactivateDepartment, toggledeactivateDepartment ] = useState(false);
  const [ confirmdeactivateDepartment, toggleconfirmdeactivateDepartment  ] = useState(false);
  const [ deptid, setdeptid ] = useState();
  const [ btnLoader, togglebtnLoader ] = useState(false);
  const [ departmentList, setdepartmentList ] = useState([]);
  const csvLinkEl = useRef();

  const CSVHeaders = [
    { label: "Department ID", key: "department_id" },
    { label: "Department Name", key: "DepartmentName" },
    { label: "Location Name", key: "locationName" },
    { label: "No of Employee", key: "NoOfEmpolyee" },
    { label: "Description", key: "departmentDescription" },
  ];

  const columns = [
    {
      title: 'Department ID',
      dataIndex: 'department_id',
      key: 'department_id',
      width: '10%',
      align: 'center',
    },
    {
      title: 'Department Name',
      dataIndex: 'DepartmentName',
      key: 'DepartmentName',
      width: '15%',
      align: 'center'
    },
    {
      title: 'Location Name',
      dataIndex: 'locationName',
      key: 'locationName',
      width: '15%',
      align: 'center',
    },
    {
      title: 'No of Employee',
      dataIndex: 'NoOfEmpolyee',
      key: 'NoOfEmpolyee',
      width: '10%',
      align: 'center'
    },
    {
      title: 'Description',
      dataIndex: 'departmentDescription',
      key: 'departmentDescription',
      width: '15%',
      align: 'center'
    }
  ];

  columns.push({
    title: 'Action',
    dataIndex: 'address',
    key: 'address',
    width: '10%',
    align: 'center',
    render: (_, record) => (
      <Styleddot>
        <Dropdown className="float-center " >
          <DropdownToggle className=" border-white btn-transparent bg-transparent" style={{border: 'none'}}>
            <MoreOutlined style={{fontSize: 22, color: "black"}}/>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem style={{color: "black"}} onClick={() => ViewDepartment(record)}>
              Edit
            </DropdownItem> 
            <DropdownItem style={{color: "black"}} onClick={() =>{setdeptid(record.department_id); deletedDepartment(true)}}>
              Deactivate
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </Styleddot>
    ),
  },);

  useEffect(() => {
    getDepartmentList();
  },[setdepartmentList]);

  const getDepartmentList = async () => {
      setDisplayDepartmentLoader(true);
    await CALL_API('admin-department', 'get', {
    }).then(function(response){
      setdepartmentList(response.reverse());
      setDisplayDepartmentLoader(false);
    }).catch(function(error) {
      console.log("[getDepartmentList], Error-->",error);
    })
  }

  const departmentfilter = departmentList.filter((val) => {
    if (val === "") {
      return val;
    }
    else if(JSON.stringify(val.status_id).toLowerCase().includes(("1").toLowerCase())){
      return val;
    }
  }) 

  const deletedDepartment = () => {
    toggledeactivateDepartment(true);
  }

  const deleteDepartment = async (department_id) => {
    togglebtnLoader(true);
    await CALL_API('admin-department', 'delete', {
      'department_id': department_id,
      'status_id': 0
    });
    togglebtnLoader(false);
    toggledeactivateDepartment(false);
    toggleconfirmdeactivateDepartment(true);
    getDepartmentList();
  }

  const ViewDepartment = (DepartmentObject, isViewOnly = false) => {
    toggleEditDepartmentModal(true);
    setDepartmentDetails({
      ...DepartmentObject,
      isViewOnly
    });
  }

  const prepareCSV = async () => {
    if (departmentList.length > 0) {
      setCSVDepartmentList(
        reduce(departmentList, function(result, list) {
          result.push({
            "Department ID": list.department_id,
            "Department Name": list.DepartmentName,
            "Location Name": list.locationName,
            "No of Employee": list.NoOfEmpolyee,
            "Description": list.departmentDescription,
          });
          return result;
        }, [])
      );
      setTimeout(() => {
        csvLinkEl.current.link.click();
      });
    } else {
      message.error(`No data available to download.`);
    }
  }

  return (
    <>
       <Row className='page-title'>
          <Col span={12} style={{ fontSize: "20px", fontWeight: "bold" }}>List of  departments</Col>
          <Col span={12} className="align-right">
            <Button type="link" style={{ border: "1px solid", marginBottom:"2%" }} onClick={prepareCSV}>
              Export CSV file
            </Button>
            <CSVLink
              header={CSVHeaders}
              data={CSVDepartmentList}
              filename={"department-list.csv"}
              asyncOnClick={true}
              ref={csvLinkEl}
            />
            <StyledButton type="primary" htmlType='submit' onClick={() => toggleAddDepartmentModal(true)} >+ Add Department</StyledButton>
          </Col>
        </Row>
      
      <Spin spinning={displayDepartmentLoader}>
        <StyledTable
          dataSource={departmentfilter}
          columns={columns}
          rowClassName={(record, index) => index % 2 === 0 ? '' :  'non-white'}
          rowKey='department_id'
          pagination={{
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            pageSize: 20,
            showSizeChanger: false
          }}
        />
      </Spin>
      
      <AddDepartment 
        isModalVisible={displayAddDepartmentModal}
        closeModal={() => toggleAddDepartmentModal(false)}
        DepartmentDetails={DepartmentDetails}
        getDepartmentList={getDepartmentList}
      /> 

      <EditDepartment
        isModalVisible={displayEditDepartmentModal}
        closeModal={() => toggleEditDepartmentModal(false)}
        getDepartmentList={getDepartmentList}
        DepartmentDetails={DepartmentDetails}
      />

      <StyleddeactivateModal
        title='Are you sure you want to deactivate this department?'
        visible={deactivateDepartment}
        width={500}
        onCancel={() => toggledeactivateDepartment(false)}
        footer={[]}
        centered
      >
        <Row className='page-title-head'>
          <Col>
            <StyledButton type="primary" htmlType='submit' onClick={() => toggledeactivateDepartment(false)}>No</StyledButton>
          </Col>
          <Col style={{ marginLeft: "5%" }}>
            <StyledButton loading={btnLoader} type="primary" htmlType='submit' onClick={() => deleteDepartment(deptid)}>{btnLoader ? 'Yes' : 'Yes'}</StyledButton>
          </Col>
        </Row>
      </StyleddeactivateModal>

      <StyledModal
        visible={confirmdeactivateDepartment}
        width={500}
        onCancel={() => toggleconfirmdeactivateDepartment(false)}
        footer={[]}
        centered
      >
        <Row className='modal-body'>
          <Col>
            <img src={Confirm} height={45} width={45} />
          </Col>
        </Row>
        <Row className='modal-body-content'>
          <Col>
            <p>This department has been deactivated</p>
          </Col>
        </Row>
      </StyledModal>
    </>
  );}

  const mapStateToProps = state => ({
    userRoleId: getUserRoleId(state),
    statusListFromID: getStatusListFromID(state, [4, 5, 6]),
    selectedDepartmentId: getSelectedDepartmentId(state),
  });

  const mapDispatchToProps = dispatch => bindActionCreators(
    {
      updateProcessingModal: toggleProcessingModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Department);