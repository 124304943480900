import { WEB_SOCKET_CONNECTION_STATUS } from 'common/Constants';
import {
    get as _get,
    find as _find,
    upperCase as _upperCase,
    filter as _filter,
    includes,
    filter
} from 'lodash';
import { dateInDetail, isDateBetweenTwoDates } from 'utils/Date';

export const getLoadingDataFirstTime = store => _get(store, `common.loadingDataFirstTime`);
export const getUserName = store => _get(store, `common.userInfo.name`);
export const getUserInfo = store => _get(store, `common.userInfo`);
export const getUserRoleId = (store) => _get(store, `common.userInfo.roleId`, '');
export const getToggleDrawer = (store) => _get(store, `common.toggleDrawer`, '');
export const getIsUserLoggedIn = (store) => _get(store, `common.isLoggedIn`, '');
export const getSelectedDepartmentId = (store) => _get(store, `common.selectedDepartmentId`, []);
export const getDepartmentList = (store) => _get(store, `common.departmentList`, []);
export const getAllDepartmentList = (store) => _get(store, `common.allDepartmentList`, []);

export const getRoleList = (store) => _get(store, `common.roleList`, []);
// export const getRoleName = (store) => _get(store, `common.roleName`, '');
export const getRoleName = (store) => {
    const roleId = parseInt(_get(store, `common.userInfo.roleId`, 0));
    return _get(_find(getRoleList(store), ({ role_id }) => {
        return role_id === roleId;
    }), 'role_name', '')
};
export const getDepartmentName = (store) => {
    // const departmentId = parseInt(_get(store, `common.selectedDepartmentId`, 0));
    const departmentId = getSelectedDepartmentId(store);
    const departmentList = getDepartmentList(store)
    const departmentNameList = []
    departmentList.map(list => {
        if (includes(departmentId, list.department_id)) {
            departmentNameList.push(list.department_name)
        }
    })
    return departmentNameList
    // return _get(_find(getDepartmentList(store), ({department_id}) => {
    // return department_id === departmentId;
    // return includes(departmentId, department_id)
    // }), 'department_name', [])
};
export const getSysteDate = (store) => _get(store, `common.date`, '');

export const getTime = (store) => {
    const dateInfo = dateInDetail(getSysteDate(store));
    return _get(dateInfo, 'time', '');
};

export const getAMPM = (store) => {
    const dateInfo = dateInDetail(getSysteDate(store));
    return _upperCase(_get(dateInfo, 'meridiem', ''));
}

export const getLongDay = (store) => {
    const dateInfo = dateInDetail(getSysteDate(store));
    return `
        ${_get(dateInfo, 'day', '')},
        ${_get(dateInfo, 'monthName', '')} ${_get(dateInfo, 'date', '')},
        ${_get(dateInfo, 'year', '')}
    `;
}

export const getLocations = (store) => _get(store, `common.locationList`, []);

export const getBreakTypeList = (store) => _get(store, `common.breakTypeList`, []);

export const getCheckInDateTime = (store) => _get(store, `common.checkInDateTime`, '');

export const getCheckOutDateTime = (store) => _get(store, `common.checkOutDateTime`, '');

export const getIsOnBreak = (store) => _get(store, `common.isOnBreak`, false);
export const getIsCheckedIn = (store) => _get(store, `common.isCheckedIn`, false);
export const getCheckInLocationId = (store) => _get(store, `common.checkInLocationId`, '');

export const getToggleSuccessModal = (store) => _get(store, `common.displaySuccessModal`, false);

export const getSuccessModalMessage = (store) => _get(store, `common.successModalMessage`, false);

export const getUserList = (store) => _get(store, `common.userList`, []);

export const getNotificationList = (store) => _get(store, `common.notificationList`, []);

export const getDepartmentUserList = (store) => {
    const departmentId = getSelectedDepartmentId(store);
    // return _filter(_get(store, `common.userList`, []), { 'department_id': departmentId });
    let output = _filter(_get(store, `common.userList`, []), (v) => includes(departmentId, v.department_id));
    return output
}

export const getToggleProcessingDrawer = (store) => _get(store, `common.displayProcessingModal`, '');

export const getLeaveCategoryList = (store) => _get(store, `common.leaveCategoryList`, []);

export const getHolidayList = (store) => _get(store, `common.holidayList`, []);

export const getHolidayListBetweenDates = (store, startDate, endDate) => {
    const holidayList = _get(store, `common.holidayList`, []);
    const holidays = filter(holidayList, ({ date }) => isDateBetweenTwoDates(date, startDate, endDate), {});
    return holidays;
};

export const getStatusList = (store) => _get(store, `common.statusList`, []);

export const getBreakId = (store) => _get(store, `common.breakId`, []);

export const getPunchId = (store) => _get(store, `common.punchId`, '');

export const getWebSocketStatus = (store) => _get(store, `common.webSocketStatus`, []);

export const getWebSocketStatusDisplay = (store) => WEB_SOCKET_CONNECTION_STATUS[getWebSocketStatus(store)];

export const getChatMessageList = (store) => _get(store, `common.chatMessageList`, []);

export const getUnreadChatMessageList = (store) => _get(store, `common.newMessageIndicatorList`, []);

export const getChatMessageQueue = (store) => _get(store, `common.chatMessageQueue`, []);

export const getChatSelectedStaffId = (store) => _get(store, `common.chatSelectedStaffId`, []);

export const getShiftTimeMaster = (store, userid) => {
    const allShifts = _get(store, `common.shiftTimeMaster`, []);
    let userShifts = filter(allShifts, { user_id: userid });
    return userShifts.length > 0 ? userShifts : filter(allShifts, { user_id: 0 });
};

export const getStatusListFromID = (store, id) => {
    return _filter(_get(store, `common.statusList`, []), ({ status_id }) => includes(id, status_id));
};

export const getProfilePic = (store) => _get(store, `common.profilePic`, []);
