import React, { useEffect, useState } from 'react';
import EmployeeCard from 'molecules/EmployeeCard';
import { Row, Col, Input, Select, Button, Spin, Modal } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { DEVICE } from 'common/Device';
import { connect } from 'react-redux';
import {
  getUserList,
  getUserRoleId,
  getAllDepartmentList,
  getDepartmentUserList,
  getSelectedDepartmentId,
} from 'redux/selectors';
import styled from '@emotion/styled';
import { CALL_API } from 'common/API';
import { map, concat, filter, lowerCase, get, size, uniqBy, includes } from 'lodash';
import Icon from 'atoms/Icon';
import AddEmployee from 'organisms/AddEmployee';
import EditEmployee from 'organisms/EditEmployee';
import { Pagination } from "antd";
import Confirm from '../../src/assets/Confirm.svg';

const StyledCol = styled(Col)`
  padding: 10px;
`;

const StyledTitle = styled.div`
  padding-left: 10px;
  padding-bottom: 10px;
`;

const StyledFilterRow = styled(Row)`
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  .selectbox{
    width:100%;
  }
  @media ${DEVICE.tablet}  {
    .selectbox{
      margin-top:10px;
      margin-bottom:10px;
    }
  }

`;

const StyledContainerRow = styled(Row)`
  padding-top:20px;
 .noEmployee{
   width:100%;
   text-align:center;
   color:grey;
   margin-top:10%;
 }
`;

const StyledRoleCol = styled(Col)`
  display:flex;
  justify-content:flex-end;
  .add-button {
    display:flex;
    justify-content:center;
    align-items:center;
    background: #5B86E5;
    border-radius: 5px;
    // font-size: 16px;
    color: #FFFFFF;
    box-shadow: none;

    padding-left: 10px;
    cursor: pointer;
  }
  .add-title{
    margin-left: 10px;
    vertical-align: text-bottom;
  }
  
  @media ${DEVICE.tablet}  {
    padding: 1rem;
    .role-button {
      width: 100%;
    }
  }
`
const StyleddeactivateModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
    padding: 35px 0px 20px 0px;
  }
  .ant-modal-content{
    box-shadow: 0px 0px 8px #00000029;
    border-radius: 5px;
  }
  .ant-modal-title {
    text-align: center;
    font-size: 16px;
  }
  .ant-modal-body{
    padding: 0px 0px 35px 0px;
  }
  .ant-modal-close-x{
    display: none;
  }
  .page-title-head{
    justify-content: center;
  }
  .ant-modal-footer {
    display: none;
  }
`;

const StyledModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
  }
  .ant-modal-content{
    box-shadow: 0px 0px 8px #00000029;
    border-radius: 5px;
  }
  .ant-modal-body{
    padding: 24px 0px 30px 0px;
  }
  .modal-body{
    justify-content: center;
    margin-top: 10px;
  }
  .ant-modal-close-x{
    color: #151515;
  }
  .modal-body-content{
    justify-content: center;
    font-size: 16px;
    color: #151515;
  }
  .ant-modal-footer {
    display: none;
  }
`;
const StyledButton = styled(Button)`
    width: 125px;
    color: white;
    background-color: #B7B7B7;
    border-radius: 5px; 
    margin-left: 5%;
`;


function Employee({
  userRoleId,
  allDepartmentList,
  selectedDepartmentId,
}) {
  
  const [ displayAddEmployeeModal, toggleAddEmployeeModal ] = useState(false);
  const [ displayEditEmployeeModal, toggleEditEmployeeModal ] = useState(false);
  const [ EmployeeDetails, setEmployeeDetails ] = useState({});
  const [ selectedDepartment, setSelectedDepartment ] = useState('');
  const [ employeeNameFilter, setEmployeeNameFilter ] = useState('');
  const [ displayEmployeeLoader, setdisplayEmployeeLoader ] = useState(false);
  const [ EmployeeList, setEmployeeList ] = useState([]);
  const [ deactivateEmployee, toggledeactivateEmployee ] = useState(false);
  const [ confirmdeactivateEmployee, toggleconfirmdeactivateEmployee  ] = useState(false);
  const [ empId, setEmpId ] = useState();
  const [ btnLoader, togglebtnLoader ] = useState(false);
  const [ employeeFilterList, setEmployeeFilterList ] = useState([])
  const [ employeeType, setEmployeeType ] = useState(1)

  // useEffect(() => {
  //   if (userRoleId !== 5) setSelectedDepartment(selectedDepartmentId);
  // }, [ selectedDepartmentId ]);

  const getFilterList = () => {
    return uniqBy(employeeNameFilter !== '' ? filter(employeeFilterList, (staff) => { return lowerCase(staff.name).indexOf(lowerCase(employeeNameFilter)) > -1 }) : employeeFilterList, 'EmployeeID');
  }

  useEffect(() => {
      var result
      if (selectedDepartment != ''){
        result = EmployeeList?.filter((data) => data.departmentId == selectedDepartment && data.status_id === employeeType);
      }
      else{
        result = EmployeeList?.filter((data) => data.status_id === employeeType);
      }
    setEmployeeFilterList(result)
  },[selectedDepartment, employeeType, EmployeeList ])


  useEffect(() => {
    getEmployeeList();
  },[setEmployeeList]);

  const getEmployeeList = async () => {
    setdisplayEmployeeLoader(true);
    await CALL_API('admin-employee', 'get', {
    }).then(function(response){
      setEmployeeList(response);
      setdisplayEmployeeLoader(false);
    }).catch(function(error) {
      console.log("[getEmployeeList], Error-->",error);
    })
  }

  const ViewEmployee = (EmployeeObject, isViewOnly = false) => {
    toggleEditEmployeeModal(true);
    setEmployeeDetails({
      ...EmployeeObject,
      isViewOnly
    });
  }

  const deletedEmployee = (EmpId) => {
    toggledeactivateEmployee(true);
    setEmpId(EmpId)  
  }

  const deleteEmployee = async (employee_id) => {
    togglebtnLoader(true);
    await CALL_API('admin-employee', 'delete', {
      'employeeId':employee_id,
      'status_id': 0
    });
    togglebtnLoader(false);
    toggledeactivateEmployee(false);
    toggleconfirmdeactivateEmployee(true);
    getEmployeeList();
  }

  // const pageSize = 18;
  // const [current, setCurrent] = useState(1)
  // const [minIndex, setMinIndex] = useState(0)
  // const totalPage = EmployeeList.length / pageSize
  // const [maxIndex, setMaxIndex] = useState(pageSize)
  // const handleChange = (page, pageSize) => {
  //   setCurrent(page)
  //   setMinIndex((page - 1) * pageSize)
  //   setMaxIndex(page * pageSize)
  // };
  
  return (
    <>
     
        <StyledTitle className='page-title-head'>List of Employees</StyledTitle>
       
      <StyledFilterRow gutter={16}>
        <Col md={6} sm={24} xs={24}>
        
            <Input placeholder="Search by employee name" prefix={<SearchOutlined />} onChange={(event) => setEmployeeNameFilter(event.target.value)} />
          
        </Col>
        <Col md={5} sm={24} xs={24}>
       
              <Select
                className='selectbox'
                placeholder="Department"
                defaultValue={''}
                options={concat({
                  department_id: '',
                  department_name: 'All Departments'
                }, allDepartmentList)}
                fieldNames={{
                  label: 'department_name',
                  value: 'department_id'
                }}
                onChange={(id) => setSelectedDepartment(id)}
              />
          
        </Col>
        <Col md={5} sm={24} xs={24}>
          { userRoleId === 5 && (
              <Select
                  className='selectbox'
                  placeholder="Employee type"
                  defaultValue={1}
                  options={[
                    {
                      value: 1,
                      label: 'Employee',
                    },
                    {
                      value: 0,
                      label: 'Ex Employee',
                    }
                  ]}
                  onChange={(id) => setEmployeeType(id)}
               />
            )
          }
        </Col>
        <Col md={8} sm={24} xs={24} className='align-right'>
        
            <StyledRoleCol>
                <Button onClick={() => toggleAddEmployeeModal(true)} className={`add-button`}><Icon name='addemployee'/><span className='add-title'>Add Employee</span></Button>
            </StyledRoleCol>
         
        </Col>
      </StyledFilterRow>
      
      
      <StyledContainerRow gutter={16}>
       { displayEmployeeLoader ? (
          <div style={{width:'100%', textAlign:'center'}}>
            <Spin spinning={displayEmployeeLoader}/>
          </div>
       ):
        <>
          {employeeFilterList?.length > 0 ? (
          <>
              {
                map(getFilterList(), ( data, index) =>
                // index >= minIndex &&
                // index < maxIndex && 
                (
                  <>
                
                  <StyledCol key={index} xs={24} sm={12} md={8} lg={6} xl={6}>
                    <EmployeeCard
                      id={data.EmployeeID}
                      name={data.name}
                      Department={data.Department}
                      Role={data.Role}
                      email_id={data.EmailId}
                      data={data}
                      getEmployeeList={getEmployeeList}
                      ViewEmployee={ViewEmployee}
                      deletedEmployee={deletedEmployee}
                      profile={data.profile_url}
                    />
                  </StyledCol>
                  </>
                ))
              }  
                {/* <div style={{width:'100%', textAlign:'right'}}>
                      <Pagination
                          pageSize={pageSize}
                          current={current}
                          total={employeeFilterList.length}
                          onChange={handleChange}
                          showSizeChanger={false}
                      /> 
                </div> */}
          </>
        ):(
          <>
             <p className='noEmployee'>No Employee's in this Department</p>
          </>
          )}
      
      </>
     }
      </StyledContainerRow>
      
        
      <AddEmployee 
          isModalVisible={displayAddEmployeeModal}
          closeModal={() => toggleAddEmployeeModal(false)}
          getEmployeeList={getEmployeeList}
          setSelectedDepartment={setSelectedDepartment}
          EmployeeList={EmployeeList}
      />
      <EditEmployee 
        isModalVisible={displayEditEmployeeModal}
        closeModal={() => toggleEditEmployeeModal(false)}
        getEmployeeList={getEmployeeList}
        EmployeeDetails={EmployeeDetails}
       
      />
      <StyleddeactivateModal
        title='Are you sure you want to terminate this Employee?'
        visible={deactivateEmployee}
        width={500}
        onCancel={() => toggledeactivateEmployee(false)}
        footer={[]}
        centered
      >
        <Row className='page-title-head'>
          <Col>
            <StyledButton type="primary" htmlType='submit' onClick={() => toggledeactivateEmployee(false)}>No</StyledButton>
          </Col>
          <Col style={{ marginLeft: "5%" }}>
            <StyledButton loading={btnLoader} type="primary" htmlType='submit' onClick={() => deleteEmployee(empId)}>{btnLoader ? 'Yes' : 'Yes'}</StyledButton>
          </Col>
        </Row>
      </StyleddeactivateModal>

      <StyledModal
        visible={confirmdeactivateEmployee}
        width={500}
        onCancel={() => toggleconfirmdeactivateEmployee(false)}
        footer={[]}
        centered
      >
        <Row className='modal-body'>
          <Col>
            <img src={Confirm} height={45} width={45} />
          </Col>
        </Row>
        <Row className='modal-body-content'>
          <Col>
            <p>This Employee has been terminated</p>
          </Col>
        </Row>
      </StyledModal>
    </>
  );
}

const mapStateToProps = state => ({
  userList: getUserList(state),
  userRoleId: getUserRoleId(state),
  allDepartmentList: getAllDepartmentList(state),
  departmentUserList: getDepartmentUserList(state),
  selectedDepartmentId: getSelectedDepartmentId(state),
});

export default connect(mapStateToProps)(Employee);