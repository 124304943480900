import React, { useState, useRef, useCallback } from 'react';
import { Modal, Row, Col, Form, Input, DatePicker, Select, message } from 'antd';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import Button from 'atoms/Button';
import { CALL_API } from 'common/API';
import moment from 'moment';
import { getMySqlDate, momentDate } from 'utils/Date';
import { concat } from 'lodash';
import { NON_EMPTY_REGEX, NON_EMPTY_NUMBER, VALIDATE_SYMBOL } from 'common/Constants';
import { getAllDepartmentList, getLocations } from 'redux/selectors';
import { STATUS_CODE } from 'common/Constants';
import { get, includes } from 'lodash';
import { useDropzone } from 'react-dropzone'

const StyledModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .ant-modal-title {
    font-weight: 600;
    text-align: center;
    font-size: 24px;
    color: #1B1B1B;
  }
  .anticon {
    color: #000000;
    width: 14px;
    height: 14px;
  }
  .ant-modal-close{
    margin-top: 14px;
  }
  .ant-modal-body {
    padding-top: 0px;
  }
  .ant-modal-footer {
    display: none;
  }
  .ant-form-item-label {
    font-weight: 500;
  }
  .ant-picker, .ant-input-number {
    width: 100%;
  }
  .ant-btn-text:focus, .ant-btn-text:hover{
    background: #5B86E5;
    color: #FFFFFF;
  }
`;

const StyledButton = styled(Button)`
    background: #5B86E5;
    border-radius: 5px;
    color: #FFFFFF;
    width: 100px;
`;

const StyledDropzone = styled.div`
  text-align: center;
  width: 100%;
  height: 40px;
  background: #e3e3e3;
  border-radius: 5px;
  display:flex;
  align-items:center;
  justify-content:center;
  cursor:pointer;
  p{
    padding-top:10px;
  }
`;

function AddEmployee({
  isModalVisible,
  closeModal,
  getEmployeeList,
  allDepartmentList,
  locationList,
}) {

  const [showLoader, toggleLoader] = useState(false);
  const [locationId, setLocationId] = useState(0);
  const [filename, setFileName] = useState('')
  const [form] = Form.useForm();
  const { Option } = Select;
  const containerRef = useRef(null);

  const onFinish = async (values) => {
    toggleLoader(true);
    const {
      employee_id,
      department_name,
      first_name,
      last_name,
      email_id,
      username,
      password,
      role_id,
      gender_name,
      start_date,
      end_date,
      national_id,
      nssf_id,
      nhif_id,
      kra_pin,
      overtime,
      profile,
      salary
    } = values;
    await CALL_API('admin-employee', 'post', {
      "employeeId": employee_id,
      "first_name": first_name,
      "last_name": last_name,
      "email_id": email_id,
      "username": username,
      "password": password,
      "role_id": role_id,
      "startDate": getMySqlDate(start_date),
      "endDate": end_date ? getMySqlDate(end_date) : '',
      "gender": gender_name,
      "location_id": locationId,
      "NSSF": nssf_id,
      "NHIF": nhif_id,
      "KRApin": kra_pin,
      "national_Id": national_id,
      "status_id": 1,
      "department_id": department_name,
      "overtime_status": overtime,
      "salary": salary
    });
    toggleLoader(false);
    form.resetFields();
    setFileName('')
    closeModal(true);
    getEmployeeList();
  }

  const Reset = () => {
    form.resetFields();
    setFileName('')
  }

  const onDrop = useCallback(acceptedFiles => {
    if (form.getFieldValue('employee_id')) {
      acceptedFiles.map(file => {
        const reader = new FileReader();
        reader.onload = async function (e) {
          if (includes(['image/jpeg', 'image/jpg', 'image/jpeg', 'image/png', 'image/tif', 'image/tiff', 'png', 'jpg', 'tif'], get(file, 'type', ''))) {
            setFileName(acceptedFiles[0].path)
            const { code } = await CALL_API('profile-upload', 'post', {
              userid: form.getFieldValue('employee_id'),
              filetype: get(file, 'type', ''),
              content: e.target.result,
            });
          }
          else {
            message.error('Please upload valid image file.')
          }
        };
        reader.readAsDataURL(file);
        return file;
      });
    }
    else {
      message.error('Please Enter Employee Id')
    }
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  const restrictPastDate = (current, element) => {
    let customDate = moment().format('YYYY-MM-DD');
    if (element === 'end_date') {
      return current < moment(form.getFieldValue('start_date'));
    }
    return current && current < moment(customDate, 'YYYY-MM-DD');
  }

  const validateDateRange = (date) => {
    const endDate = moment(form.getFieldValue('end_date'));
    if (endDate < date) {
      form.setFieldsValue({ 'end_date': date })
    }
  }

  return (
    <>
      <StyledModal
        title='Add Employees'
        visible={isModalVisible}
        onCancel={() => { closeModal(false); form.resetFields(); setFileName('') }}
        width={600}
        footer={[]}
      >
        <Form form={form} name='addemployeeform' layout='vertical'
          initialValues={{
            employee_id: '',
            department_name: '',
            first_name: '',
            last_name: '',
            email_id: '',
            username: '',
            password: '',
            role_name: '',
            gender: '',
            locationId: '',
            start_date: momentDate(new Date()),
            // end_date: momentDate(new Date()),
            national_id: '',
            nssf_id: '',
            nhif_id: '',
            kra_pin: ''
          }}
          onFinish={onFinish}
        >
          <Row gutter={20}>
            <Col xs={24} sm={24} md={12}>
              <Form.Item name="employee_id" label="Employee ID"
                rules={[
                  {
                    required: true,
                    pattern: NON_EMPTY_NUMBER,
                    message: 'Please enter valid Employee ID',
                  },
                ]}
              >
                <Input placeholder="Employee ID" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} ref={containerRef}>
              <Form.Item name="department_name" label="Department"
                rules={[
                  {
                    required: true,
                    pattern: NON_EMPTY_REGEX,
                    message: 'Please Select Department name',
                  },
                ]}
              >
                <Select
                  style={{ width: '100%' }}
                  options={concat({
                    department_id: '',
                    department_name: 'Select Department'
                  }, allDepartmentList)}
                  fieldNames={{
                    label: 'department_name',
                    value: 'department_id'
                  }}
                  getPopupContainer={() => containerRef.current}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item name="first_name" label="First name"
                rules={[
                  {
                    required: true,
                    pattern: NON_EMPTY_REGEX,
                    message: 'Please enter First name',
                  },
                  {
                    required: false,
                    pattern: VALIDATE_SYMBOL,
                    message: 'Name should not contain special characters'
                  }
                ]}
              >
                <Input placeholder="First name" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item name="last_name" label="Last name"
                rules={[
                  {
                    required: true,
                    pattern: NON_EMPTY_REGEX,
                    message: 'Please enter Last name',
                  },
                  {
                    required: false,
                    pattern: VALIDATE_SYMBOL,
                    message: 'Name should not contain special characters'
                  }
                ]}
              >
                <Input placeholder="Last name" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item name="username" label="Username"
                rules={[
                  {
                    required: true,
                    pattern: NON_EMPTY_REGEX,
                    message: 'Please enter Username',
                  }, {
                    required: false,
                    pattern: VALIDATE_SYMBOL,
                    message: 'Name should not contain special characters'
                  }
                ]}
              >
                <Input placeholder="Username" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item name="password" label="Password"
                rules={[
                  {
                    required: true,
                    pattern: NON_EMPTY_REGEX,
                    message: 'Please enter Password',
                  },
                ]}
              >
                <Input.Password placeholder="Password" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item name="email_id" label="Email ID"
                rules={[
                  {
                    required: true,
                    pattern: NON_EMPTY_REGEX,
                    message: 'Please enter EmailID',
                  },
                ]}
              >
                <Input placeholder="Email ID" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} ref={containerRef}>
              <Form.Item name="role_id" label="Role"
                rules={[
                  {
                    required: true,
                    pattern: NON_EMPTY_REGEX,
                    message: 'Please Select role',
                  },
                ]}
              >
                <Select placeholder="Select role" getPopupContainer={() => containerRef.current}>
                  <Option value="1">HR</Option>
                  <Option value="2">HOD</Option>
                  <Option value="3">ASSISTANT HOD</Option>
                  <Option value="4">STAFF</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} ref={containerRef}>
              <Form.Item name="gender_name" label="Gender"
                rules={[
                  {
                    required: true,
                    pattern: NON_EMPTY_REGEX,
                    message: 'Please Select gender',
                  },
                ]}
              >
                <Select placeholder="Select gender" getPopupContainer={() => containerRef.current}>
                  <Option value="male">Male</Option>
                  <Option value="female">Female</Option>
                </Select>
              </Form.Item>

            </Col>
            <Col xs={24} sm={24} md={12} ref={containerRef}>
              <Form.Item name="overtime" label="Overtime Eligible"
                rules={[
                  {
                    required: true,
                    pattern: NON_EMPTY_REGEX,
                    message: 'Please Select',
                  },
                ]}
              >
                <Select placeholder="Select" getPopupContainer={() => containerRef.current}>
                  <Option value="yes">Yes</Option>
                  <Option value="no">No</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} ref={containerRef}>
              <Form.Item label='Location Name' name='locationId'
                rules={[
                  {
                    required: true,
                    pattern: NON_EMPTY_REGEX,
                    message: 'Please Select Location',
                  },
                ]}
              >
                <Select placeholder="Select Location"
                  options={concat({
                    location_id: '',
                    location_name: 'Select Location'
                  }, locationList)}
                  fieldNames={{
                    label: 'location_name',
                    value: 'location_id'
                  }}
                  value={locationId}
                  onChange={val => setLocationId(val)}
                  getPopupContainer={() => containerRef.current}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item label='Start date' name='start_date'
                rules={[
                  {
                    required: true,
                    pattern: NON_EMPTY_REGEX,
                    message: 'Please enter start date!',
                  },
                  () => ({
                    validator(rule, value) {
                      const date = moment(value);
                      return date.isValid() ? Promise.resolve() : Promise.reject();
                    }
                  })
                ]}
              >
                <DatePicker
                  // disabledDate={(current) => restrictPastDate(current, 'start_date')} 
                  onChange={validateDateRange}
                  placeholder="Select start date"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.start_date !== currentValues.start_date}>
                {() => (
                  <Form.Item label='End date' name='end_date'
                  >
                    <DatePicker
                      // disabledDate={(current) => restrictPastDate(current, 'end_date')} 
                      placeholder="Select end date"
                    />
                  </Form.Item>
                )}
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item name="salary" label=" Salary"
              // rules={[
              //   {
              //     required: true,
              //     pattern: NON_EMPTY_REGEX,
              //     message: 'Please enter salary',
              //   },
              // ]}
              >
                <Input placeholder="Salary" type='number' />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item name="national_id" label="National ID"
                rules={[
                  {
                    required: true,
                    pattern: NON_EMPTY_REGEX,
                    message: 'Please enter NationalID',
                  },
                ]}
              >
                <Input placeholder="National ID" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item name="nssf_id" label="NSSF"
                rules={[
                  {
                    required: true,
                    pattern: NON_EMPTY_REGEX,
                    message: 'Please enter NSSF',
                  },
                ]}
              >
                <Input placeholder="NSSF" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item name="nhif_id" label="NHIF"
                rules={[
                  {
                    required: true,
                    pattern: NON_EMPTY_REGEX,
                    message: 'Please enter NHIF',
                  },
                ]}
              >
                <Input placeholder="NHIF" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item name="kra_pin" label="KRA Pin"
                rules={[
                  {
                    required: true,
                    pattern: NON_EMPTY_REGEX,
                    message: 'Please enter KRA Pin',
                  },
                ]}
              >
                <Input placeholder="KRA Pin" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} {...getRootProps()}>
              <Form.Item name="profile" label="Profile Pic">
                <Input {...getInputProps()} type='file' />
                <StyledDropzone>
                  <p>{filename !== '' ? <>{filename}</> : <> choose file</>}</p>
                </StyledDropzone>
              </Form.Item>
            </Col>
          </Row>
          <div className='align-center'>
            <StyledButton type='primary' onClick={() => Reset()}>
              Reset
            </StyledButton>
            <StyledButton loading={showLoader} type='primary' htmlType='submit'>
              {showLoader ? 'Create' : 'Create'}
            </StyledButton>
          </div>
        </Form>
      </StyledModal>
    </>

  );
}

const mapStateToProps = state => ({
  allDepartmentList: getAllDepartmentList(state),
  locationList: getLocations(state)
});

export default connect(mapStateToProps, null)(AddEmployee);