import React from 'react';
import Icon from 'atoms/Icon';
import { ReadyState } from 'react-use-websocket';
import { DeveloperBoardOffOutlined } from '@mui/icons-material';
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
// const {
// REACT_APP_CUSTOM_NODE_ENV,
// REACT_APP_END_POINT,
// REACT_APP_SERVICE_URL,
// REACT_APP_LOGIN_URL,
// } = process.env;
// const END_POINT = REACT_APP_END_POINT;

const ORIGIN = window.location.origin;
// export const LOGIN_URL = REACT_APP_CUSTOM_NODE_ENV === 'PROXY' ? `proxy_api/${REACT_APP_LOGIN_URL}` : `${PROTOCOL}//${END_POINT}${REACT_APP_LOGIN_URL}`;
// export const SERVICE_URL = REACT_APP_CUSTOM_NODE_ENV === 'PROXY' ? `proxy_api/${REACT_APP_SERVICE_URL}` : `${PROTOCOL}//${END_POINT}${REACT_APP_SERVICE_URL}`;

// const LIVE = 'https://w6y7ak6jij.execute-api.af-south-1.amazonaws.com/Prod/';
const TEST = 'https://7m143zlslf.execute-api.ap-southeast-2.amazonaws.com/Prod/';
const DEV = 'http://127.0.0.1:3000/';

const LIVE_SOCKET = 'wss://4tb4dwq7dg.execute-api.af-south-1.amazonaws.com/Prod';
const TEST_SOCKET = 'wss://49ycl8a0eg.execute-api.us-east-1.amazonaws.com/Prod';
const DEV_SOCKET = 'wss://49ycl8a0eg.execute-api.us-east-1.amazonaws.com/Prod';
export const SERVICE_URL = TEST;

// export const SERVICE_URL = ORIGIN.indexOf('localhost') > 0 ? 'http://127.0.0.1:3000/' : 'https://yx8uxlxs31.execute-api.us-east-1.amazonaws.com/Prod/';
// export const SERVICE_URL = 'https://w6y7ak6jij.execute-api.af-south-1.amazonaws.com/Prod/'
// export const SERVICE_URL = 'https://rcgzyi1jr1.execute-api.us-east-1.amazonaws.com/Prod/';
// export const SERVICE_URL = 'https://yx8uxlxs31.execute-api.us-east-1.amazonaws.com/Prod/'
// export const SERVICE_URL = 'http://127.0.0.1:3000/';

export const SOCKET_URL = ORIGIN.indexOf('localhost') > -1 ? TEST_SOCKET : (ORIGIN.indexOf('test') > -1 ? TEST_SOCKET : LIVE_SOCKET);

export const NON_EMPTY_REGEX = /^(?!\s*$).+/;

export const NON_EMPTY_SINGLE_NUMBER = /\b[0-9]\b/;

export const NON_EMPTY_NUMBER = /^\d+$/;

export const NON_CHARACTER = /^[0-9\.\-\+]+$/;

//Validate symbol except space
export const VALIDATE_SYMBOL = /^[^<>*|/\\~#{}()[\],$^%&+=_@'?."]+$/g;

export const PUNCH_ACTIONS = {
  PUNCH_IN: 1,
  PUNCH_OUT: 2,
  BREAK_START: 3,
  BREAK_END: 4
}

export const STATUS = {
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
  ERROR: 'ERROR',
  RECORD_NOT_FOUND: 'RECORD_NOT_FOUND',
};

const dashboardMenu = {
  key: 'dashboard',
  icon: <Icon name='dashboard' />,
  path: 'dashboard',
  title: 'Dashboard',
};

const adminDashboardMenu = {
  key: 'dashboard',
  path: 'dashboard',
  title: 'Dashboard',
  icon: <DashboardCustomizeIcon style={{ fontSize: '20px' }} />,
}

const trendsMenu = {
  key: 'trends',
  path: 'trends',
  title: 'Trends',
  icon: < StackedBarChartIcon style={{ fontSize: '20px' }} />,
}

const demographicsMenu = {
  key: 'demographics',
  path: 'demographics',
  title: 'Demographics',
  icon: <ModelTrainingIcon style={{ fontSize: '20px' }} />,     
}

const productivityMenu = {
  key: 'productivity',
  path: 'productivity',
  title: 'Productivity',
  icon: <SettingsApplicationsIcon style={{ fontSize: '20px' }} />,  
}

const approvalMenu = {
  key: 'approvals',
  icon: <Icon name='approvals' />,
  path: 'approvals',
  title: 'Approvals',
};

const calendarMenu = {
  key: 'calendar',
  icon: <Icon name='calendar' />,
  path: 'calendar',
  title: 'Calendar',
};

const locationMenu = {
  key: 'location',
  icon: <Icon name='location' />,
  path: 'location',
  title: 'Location',
};

const myCalendarMenu = {
  key: 'calendar',
  icon: <Icon name='calendar' />,
  path: 'calendar',
  title: 'My Calendar',
};

const teamMenu = {
  key: 'team',
  icon: <Icon name='team' />,
  path: 'team',
  title: 'Teams',
};

const myTeamMenu = {
  key: 'team',
  icon: <Icon name='team' />,
  path: 'team',
  title: 'My Teams',
};

const myDocumentMenu = {
  key: 'mydocuments',
  icon: <Icon name='document' />,
  path: 'mydocuments',
  title: 'My Document',
};

const taskMenu = {
  key: 'task',
  icon: <Icon name='task' />,
  path: 'task',
  title: 'Task',
};

const myTaskMenu = {
  key: 'task',
  icon: <Icon name='task' />,
  path: 'task',
  title: 'My Task',
};

const myLeaveReport = {
  key: 'leavereport',
  icon: <Icon name='leave' />,
  path: 'myleavereport',
  title: 'My Leave History',
};

const reportMenu = {
  key: 'reports',
  icon: <Icon name='report' />,
  title: 'Reports',
  subMenu: [
    {
      key: 'attendance-report',
      // path: 'attendance-report',
      title: 'Attendance',
      icon: <Icon name='attendance' />,
      subMenu: [
        {
          key: 'attendance-report-daily',
          path: 'attendance-report',
          title: 'Daily',
          icon: <DoneAllIcon style={{ fontSize: '20px' }} />,
        },
        {
          key: 'attendance-report-monthly',
          path: 'attendance-report-monthly',
          title: 'Monthly',
          icon: <CalendarMonthIcon style={{ fontSize: '20px' }} />,
        },
      ]
    },
    {
      key: 'leave-report',
      path: 'leave-report',
      title: 'Leave',
      icon: <Icon name='leave' />,
    },
    {
      key: 'break-report',
      path: 'break-report',
      title: 'Break',
      icon: <Icon name='break' />,
    },
    {
      key: 'overtime-report',
      path: 'overtime-report',
      title: 'Overtime',
      icon: <Icon name='overtime' />,
    },
  ],
};

const ExceptionReport = {
  key: 'Exception Reports',
  icon: <Icon name='exceptionreport' />,
  title: 'Exception Reports',
  subMenu: [
    {
      key: 'Late check-in',
      path: 'Late-check-in',
      title: 'Late check-in',
      icon: <Icon name='latecheckin' />,
    },
    {
      key: 'Early check-out',
      path: 'Early-check-out',
      title: 'Early check-out',
      icon: <Icon name='earlycheckout' />,
    },
    {
      key: 'Absent report',
      path: 'Absent-report',
      title: 'Absent report',
      icon: <Icon name='absentreport' />,
    },

  ],
};

const employeeMenu = {
  key: 'employee',
  icon: <Icon name='employee' />,
  path: 'employee',
  title: 'Employee',
};

const departmentMenu = {
  key: 'department',
  icon: <Icon name='department' />,
  path: 'department',
  title: 'Department',
};

const roleMenu = {
  key: 'role',
  icon: <Icon name='role' />,
  path: 'roles',
  title: 'Role',
};
const shiftMenu = {
  key: 'shift',
  icon: <TrackChangesIcon style={{ fontSize: '20px' }} />,
  path: 'shift',
  title: 'Shift',
};

export const HR_MENU = [
  dashboardMenu,
  approvalMenu,
  calendarMenu,
  teamMenu,
  reportMenu,
  ExceptionReport,
];

export const CEO_MENU = [
  dashboardMenu,
  approvalMenu,
  calendarMenu,
  teamMenu,
  reportMenu,
  ExceptionReport,
]

export const HOD_MENU = [
  dashboardMenu,
  approvalMenu,
  calendarMenu,
  teamMenu,
  taskMenu,
  reportMenu,
  ExceptionReport,
];

export const STAFF_MENU = [
  dashboardMenu,
  myCalendarMenu,
  myTeamMenu,
  myDocumentMenu,
  myTaskMenu,
  myLeaveReport
];

export const ADMIN = [
  adminDashboardMenu,
  trendsMenu,
  demographicsMenu,
  productivityMenu,
  // departmentMenu,
  // locationMenu,
  // employeeMenu,
  // roleMenu,
  // shiftMenu
];

export const MENU = {
  1: HR_MENU,
  2: HOD_MENU,
  3: HOD_MENU,
  4: STAFF_MENU,
  5: ADMIN,
  6: CEO_MENU
};

export const STATUS_CODE = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  INVALID_PAYLOAD: 'INVALID_PAYLOAD',
  AUTHENTICATION_FAILED: 'AUTHENTICATION_FAILED',
  AUTHENTICATION_SUCCESSFUL: 'AUTHENTICATION_SUCCESSFUL',
  USER_DISABLED: 'USER_DISABLED',
  VALID_SESSION: 'VALID_SESSION',
  SAME_PASSWORD: 'SAME_PASSWORD',
  INVALID_CODE: 'INVALID_CODE',
  RECORD_NOT_FOUND: 'RECORD_NOT_FOUND',
  INVALID_SESSION: 'INVALID_SESSION',
  PASSWORD_UPDATED: 'PASSWORD_UPDATED',
  RECORD_EXIST: 'RECORD_EXIST',
  OUTSIDE_ALLOWED_PROXIMITY: 'OUTSIDE_ALLOWED_PROXIMITY',
  PUNCH_IN_SUCCESSFUL: 'PUNCH_IN_SUCCESSFUL',
  PUNCH_OUT_SUCCESSFUL: 'PUNCH_OUT_SUCCESSFUL',
  BREAK_STARTED: 'BREAK_STARTED',
  BREAK_ENDED: 'BREAK_ENDED',
  SOMETHING_WENT_WRONG: 'SOMETHING_WENT_WRONG',
  TASK_SAVED: 'TASK_SAVED',
  TASK_DELETED: 'TASK_DELETED',
  LEAVE_EXCEED: 'LEAVE_EXCEED',
  ANNUAL_LEAVE_AVAILABLE: 'ANNUAL_LEAVE_AVAILABLE',
};

export const LEAVE_INFO = {
  AVAILABLE: 'Available',
  CONSUMED: 'Consumed',
};

export const USER_REPORT = {
  ONTIME: 'OnTime',
  LATE: 'Late',
  LEAVE: 'Leave',
  EARLYEXIT: 'Early Exit',
  ABSENT: 'Absent'
}

export const LEAVE_ICON = {
  7: 'calendar2',
  1: 'medical_box',
  5: 'pregnant_woman',
  6: 'baby_carrier'
}

export const REPORT_DURATION_TYPES = [
  { value: 2, label: 'Monthly Report' },
  { value: 1, label: 'Custom Date' },
];

export const REPORT_DURATION_TYPES1 = [
  { value: 2, label: 'Monthly Report' },
];

export const APPROVAL_FILTER_TYPES = [
  { value: 4, label: 'All' },
  { value: 1, label: 'Custom Date' },
  { value: 2, label: 'Monthly' },
  // { value: 3, label: 'Weekly' },

];

export const CHECKIN_REPORT_FILTER_TYPES = [
  { value: 1, label: 'Custom Date' },
  { value: 2, label: 'Monthly' },
  // { value: 3, label: 'Weekly' },
];
export const MONTH_LIST = [
  { index: 1, value: 'January', label: 'January' },
  { index: 2, value: 'February', label: 'February' },
  { index: 3, value: 'March', label: 'March' },
  { index: 4, value: 'April', label: 'April' },
  { index: 5, value: 'May', label: 'May' },
  { index: 6, value: 'June', label: 'June' },
  { index: 7, value: 'July', label: 'July' },
  { index: 8, value: 'August', label: 'August' },
  { index: 9, value: 'September', label: 'September' },
  { index: 10, value: 'October', label: 'October' },
  { index: 11, value: 'November', label: 'November' },
  { index: 12, value: 'December', label: 'December' },
]

export const WEB_SOCKET_CONNECTION_STATUS = {
  [ReadyState.CONNECTING]: 'Connecting',
  [ReadyState.OPEN]: 'Open',
  [ReadyState.CLOSING]: 'Closing',
  [ReadyState.CLOSED]: 'Closed',
  [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
};